import React from "react";

export default class LoadingComponent extends React.PureComponent {

    render() {
        if(this.props.isForRowLoading) {
            return (
                <div className="d-flex my-2" id="rowLoading" style={this.props.styles}>
                    <div className="spinner-grow spinner-grow-sm text-primary" style={{ width:'.5rem', height:'.5rem'}} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm text-primary mx-1" style={{ width:'.5rem', height:'.5rem'}} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm text-primary" style={{ width:'.5rem', height:'.5rem'}} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="d-flex justify-content-center" style={this.props.styles}>
                    <div className="spinner-border text-primary " role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
        }
    }
}
