function AdidModal() {
    return (
        <div className="modal fade" id="adidModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">What is my ADID?</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p className="lh-1">
                        ADID is your Active Directory ID or user name.
                        This is used, along with your active directory password or network password, is used to login into internal services.
                    </p>
                    <p className="lh-1">
                        Make sure that you know the password to the AD username that you provide.
                        The AD username you provide will be used when provisioning LinOTP and logging-in in this dashboard.
                    </p>
                    <ul className="fs-6 lh-1">
                        <li>For <strong>Retail</strong>, your ADID is printed on your workstation wallpaper, under the value "User Name".</li>
                        <li>
                            For <strong>SMIC</strong> or <strong>Supermalls</strong>,
                            use the username portion from your email. E.g., if your email is juan.delacruz@sminvestments.com, your ADID is juan.delacruz
                        </li>
                        <li>
                            For other companies, this is usually the username you use to login into your workstation, or when logging in to shared services or network resources.
                        </li>
                    </ul>
                    <p className="lh-1">If you don't know your ADID, please consult with your local IT helpdesk.</p>
                    <p className="lh-1 text-danger">You must know the matching password for this ADID. This will be used for authentication.</p>
                </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default AdidModal;