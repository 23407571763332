import './loginAndSignup.scss'
import React from 'react';
import OtpInput from "react-otp-input";
import axios from "axios";
import {  setAuthTokens, isLoggedIn } from "axios-jwt";
import AdidModal from "../Modals/adidModal"
import LoadingComponent from "../ReusableComponents/loading"
// import Logo from '';

axios.defaults.baseURL = process.env.NODE_ENV === 'test' ? '' : process.env.REACT_APP_API_DOMAIN;
axios.interceptors.request.use(function (config) {
    localStorage.setItem('LAST_API_CALL', (new Date()).getTime())
    return config;
});

export default class LoginAndSignup extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props)

        this.state = {
            OTP : undefined,
            email : '',
            email_register : '',
            password : '',  
            errorMsg : '',
            isLoading : false,
            adid : '',
            signUpMsg : ''
        }

        this.handleOTPChange = this.handleOTPChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderLoginForm = this.renderLoginForm.bind(this);
        this.renderSignUpForm = this.renderSignUpForm.bind(this);
        this.handleSignUpClick = this.handleSignUpClick.bind(this);
        this.handleClearForm = this.handleClearForm.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.checkIfLoggedIn()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    
    handleOTPChange(otp) {
        this.setState({
            OTP : otp
        })
    }

    handleInputChange(e){
        const { name, value } = e.target;
        this.setState({ 
            [name]: value 
        });
    }

    handleKeyPress(e) {
        if (e.which === 13 || e.keyCode === 13) {
            e.preventDefault();
            document.getElementById('sign_in_btn').click();
        }
    }

    checkIfLoggedIn() {
        if(isLoggedIn()) {
            const _this = this;
            const { from } = _this.props.location.state || { from: { pathname: "/" } };
            _this.props.history.replace(from)
        }
    }

    handleLoginSubmit(e) {
        const _this = this;

        if(e) {
            e.preventDefault();
        }

        _this.setState({
            isLoading : true
        },()=>{
            axios.post('/token/',{
                otp : this.state.OTP,
                username : this.state.email,
                password : this.state.password,
            }).then(function(response) {
                if (_this._isMounted) { 
                    //Set Token
                    setAuthTokens({
                        accessToken: response.data.access,
                        refreshToken: response.data.refresh
                    })
                    _this.setState({
                        isLoading : false,                
                        errorMsg : ''
                    },()=> {
                        _this.checkIfLoggedIn()
                    })
                }
            }).catch(function(error) {
                const errorMsgDisplay = error && error.response && error.response.data ? Object.values(error.response.data): error.message
                if (_this._isMounted) {
                    _this.setState({
                        errorMsg : errorMsgDisplay,
                        isLoading : false,
                        OTP : undefined,
                        password : ''
                    })
                }
            });
        })

        return false
    }

    handleSignUpClick(e) {
        const _this = this;
        if(e) {
            e.preventDefault();
        }

        _this.setState({
            isLoading : true
        },()=>{
            axios.post('/register/',{
                email : this.state.email_register,
                adid : this.state.adid,
            }).then(function(response) {
                _this.setState({
                    isLoading : false,                
                    signUpMsg : 'success:Request sent. Kindly check your email for the details.'
                })
            }).catch(function(error) {
                const errorSignUpMsgDisplay = error && error.response && error.response.data ? 'error:'+ Object.values(error.response.data): 'error:Something went wrong. Contact the admin.';
                _this.setState({
                    signUpMsg : errorSignUpMsgDisplay,
                    isLoading : false
                })
            });
        })

        return false
    }

    handleClearForm(){
        this.setState({
            email: '',
            password: '',
            adid: '',
            email_register : '',
            errorMsg : '',
            signUpMsg : '',
            OTP : undefined
        })
    }

    renderLoginForm() {
        const isDisabled = (this.state.OTP && this.state.OTP.length < 6) || !this.state.OTP ? true : false;
        return <form data-testid="login-form" onKeyPress={this.handleKeyPress} onSubmit={this.handleLoginSubmit}>  
                     <div className="p-2">
                        <p className="fs-6 mb-0">Sign in with your email and password</p>
                        <p style={{fontSize:'.8em'}}>You can use your active directory credentials</p>
                         <input type="email" id="inputEmail" data-testid="email" className="form-control" placeholder="Email address" 
                            name="email" onChange={this.handleInputChange} value={this.state.email} required autoFocus/>
                        <input type="password" id="inputPassword" data-testid="password" className="form-control" placeholder="Password" 
                            name="password" onChange={this.handleInputChange} value={this.state.password} required />
                    </div>
                    <div className="text-center">
                                <OtpInput
                                name="otp-input"
                                containerStyle="otp-container"
                                inputStyle="otp-input"
                                onChange={this.handleOTPChange}
                                value={this.state.OTP}
                                numInputs={6}
                                separator={<span className="mx-2"></span>}
                            />
                            <span className="text-muted"><small>Enter OTP Here</small></span>
                            <br/>
                            <span className="text-danger"><small><strong>{this.state.errorMsg}</strong></small></span>
                    </div>
                    <div className="px-3 py-2 d-grid">
                        {
                            this.state.isLoading ? 
                            <LoadingComponent />
                        :
                            <button className="btn btn-lg btn-primary my-2" id="sign_in_btn" data-testid="sign-in-btn" type="submit" disabled={isDisabled}>
                                Log in
                            </button>
                        }

                    </div>
                </form>
    }

    renderSignUpForm() {
        const msgDisplay = this.state.signUpMsg !== '' ? this.state.signUpMsg.split(':') : ''
        return <form data-testid="signup-form" onSubmit={this.handleSignUpClick}>  
            <div className="p-2">
                <p className="fs-6 mb-0">Fill up the details below: </p>   
                <p style={{fontSize:'.8em'}}>Your request must be approved first to be able to login.</p>
                <input type="email" id="inputEmail" data-testid="email_register" className="form-control" placeholder="Email address" 
                name="email_register" onChange={this.handleInputChange} value={this.state.email_register} required autoFocus/>
                <input type="text" id="inputADID" data-testid="adid" className="form-control" placeholder="AD ID" 
                    name="adid" onChange={this.handleInputChange} value={this.state.adid} required />      
                <button type="button" className="btn btn-sm btn-link p-0" data-bs-toggle="modal" data-bs-target="#adidModal">
                    What is my ADID <i className="bi bi-question-circle"></i>
                </button>
            </div>
        <div className="text-center">
            <small data-testid="signup_msg" className={msgDisplay[0] === 'success' ? 'text-success' : 'text-danger'}><strong>{msgDisplay[1]}</strong></small>
        </div>
        <div className="px-3 py-2 d-grid text-center">
            {
                this.state.isLoading ? 
                <LoadingComponent/>
            :
                <button className="btn btn-lg btn-outline-primary btn-block mt-3" id="sign_up_btn" type="submit">
                    Sign Up
                </button>
            }

        </div>
    </form>
    }

    render() {
        return (
        <>
            <div className="form-signin" data-testid="login-signup-parent-div" id="loginAndSignupForm">
                {/* <img
                    className="form-logo d-flex justify-content-center"
                    src={Logo}
                    alt="Logo"
                /> */}
                <ul className="nav nav-tabs nav-justified mt-4" id="formsTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active fs-5" id="login-tab" data-testid="login-tab" data-bs-toggle="tab" onClick={this.handleClearForm}  data-bs-target="#loginForm" role="tab" aria-selected="true">Log In</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link fs-5" id="signup-tab" data-testid="signup-tab" data-bs-toggle="tab" onClick={this.handleClearForm}  data-bs-target="#signupForm" role="tab" aria-selected="false">Sign-Up</button>
                    </li>
                </ul>
                <div className="tab-content" id="formsTabContent">
                    <div className="tab-pane fade show active" id="loginForm" role="tabpanel" aria-labelledby="login-tab">
                        { this.renderLoginForm() }
                    </div>
                    <div className="tab-pane fade" id="signupForm" role="tabpanel" aria-labelledby="signup-tab">
                        { this.renderSignUpForm() }
                    </div>
                </div>
            </div>

            <AdidModal/>
        </>
        )
    }
}