
export const computeDate =(partOfDate,numberToDeduct, date = new Date()) => {

    switch(partOfDate) {
        case 'hour' :
            date.setHours(date.getHours() - numberToDeduct)
            break;

        case 'day' :
            date.setDate(date.getDate() - numberToDeduct)
            break;
        case 'week' :
            date.setDate(date.getDate() - (numberToDeduct*7))
            break;    

        default :
            new Date()
    }
    
    return date
}
