import React from 'react';
import DatePicker,  { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {computeDate} from "./DateComputations"

export default class DateRangePicker extends React.PureComponent {
    renderPreDefinedOptions() {       
        
        const options = this.props.predefinedOptions.map(option => {
            return <option key={option.label} value={option.value}>{ option.label} </option>
        })

        return <select className="form-select form-select-sm" value={this.props.selectedPreDefineDate}  onChange={this.props.onDropdownChange}>
            {options}
      </select> 
    }

    renderCustomDateFilter() {
        const CustomDateFilterContainer = ({ className, children }) => {
            return (
                <CalendarContainer className={className}>
                <div className="text-center px-3 py-1"style={{ background: "#f0f0f0" }}>
                    {this.renderPreDefinedOptions()}
                </div>
                <div style={{ position: "relative" }}>
                    {children}
                </div>
                </CalendarContainer>
            );
        };

       return <>
        <DatePicker
                selectsRange = { true }
                startDate = { this.props.startDate }
                endDate = { this.props.endDate }
                onChange={ this.props.onDateChange}
                className="custom-date-range-picker"
                dateFormat="MM/dd/yyyy h:mm aa"
                calendarContainer= { CustomDateFilterContainer }
                popperPlacement="bottom"
                minDate={computeDate('week', 1, new Date())}
                maxDate={new Date()}
                
            />
       </>
    }

    render() {
        return(
            <div className='col-3'>
                {this.renderCustomDateFilter()}
            </div>
            
        )
    }
}