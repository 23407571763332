import React, { PureComponent } from 'react';

import './App.scss';
import './index.scss';

import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import {  isLoggedIn, clearAuthTokens } from "axios-jwt"; 

import LoginAndSignup from './LoginAndSignup/loginAndSignup';
import Main from './Main/main';


const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
          />
        )
      }
    />
  );
};


class App extends PureComponent {

	state = {
		intervalID : 0
	}
	constructor() {
		super()
		this.compareTimeAndLogout = this.compareTimeAndLogout.bind(this)
		this.logOut = this.logOut.bind(this)
	}
	
    componentDidMount()  {
		const intervalID = setInterval(()=> this.compareTimeAndLogout(localStorage.getItem("LAST_API_CALL"), (new Date()).getTime()),300000) //call every 5mins
	  	this.setState({ intervalID })
	}
	
	componentWillUnmount() {
		clearInterval(this.state.intervalID)
	}
	
    compareTimeAndLogout(lastApiCallTime, currentTime ) {
		if(lastApiCallTime && currentTime) {
			const time = 1800000 // 30 mins (in milliseconds)
			const doLogout = currentTime - lastApiCallTime >= time;// if more than 30min without no api calls
			if (process.env.NODE_ENV === 'development') {
				console.log("DEVELOPMENT ENVIROMENT. Not respecting logout trigger for faster debugging.")
			} else if(doLogout) {
				this.logOut();
			}
		}

		if (process.env.NODE_ENV === 'development') {
			console.log("DEVELOPMENT ENVIROMENT. Not respecting logout trigger for faster debugging.")
		} else if(!lastApiCallTime) {
			this.logOut();
		}
	}
	
	logOut() {
		clearAuthTokens();
		//refresh only when not on login
		if(window.location.hash.indexOf('/login') === -1) {
			window.location.reload();
		}
	}

	render() {
		return (
		<HashRouter>
			<Switch>
				<Route path="/login" component={LoginAndSignup} exact/>   
				<PrivateRoute path="/data-api-portal" component={Main} />
				<Redirect exact from="/"  to="/data-api-portal" />
			</Switch>
		</HashRouter> 
		)
	}
}

export default App;