import React  from "react";
import { NavLink, useRouteMatch  } from 'react-router-dom';
import { clearAuthTokens } from 'axios-jwt'

// export default class HeaderTabs extends React.PureComponent {

//     renderTabs() {
//         return this.props.tabs.map(val => {
//             return <NavLink  key={val.title} to={ val.path } className="nav-link" activeClassName="active"><div className="pb-2 pt-1">{val.title}</div></NavLink>
//         })
//     }

//     render() {
//         return (
//             <nav className="navbar navbar-expand-lg navbar-light bg-light">
//                 <div className="container-fluid">
//                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                         <span className="navbar-toggler-icon"></span>
//                     </button>
//                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                     <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                         {this.renderTabs()}
//                     </ul>
//                     </div>
//                 </div>
//             </nav>
//         )
//     }
// }

function OnLogoutClick() {
    clearAuthTokens()
    window.location.reload()
}

function RenderTabs(props) {
    let { path } = useRouteMatch();

    return (
        <>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" id="mainTabs">
                {
                    props.tabs.map(val => {
                        return <NavLink  key={val.title} to={ `${path}` + val.path} className="nav-link text-white" activeClassName="active"><div className="pb-2 pt-1">{val.title}</div></NavLink>
                    })
                }
            </ul>
            <button type="button" className="btn btn-sm btn-link text-white" onClick={OnLogoutClick}>Logout</button>
        </>
    )
}

export default function RenderHeaderTabs(props) {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-primary">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {RenderTabs(props)}
                    </div>
                </div>
            </nav>
        )
}