import React, { useEffect, useState } from 'react';
import RenderHeaderTabs from './header';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import TokenManagement from '../PageComponents/TokenManagement/TokenManagement';
import UsageActivity from '../PageComponents/UsageActivity/UsageActivity';
import MainApiClient from '../ApiClient/apiClient'
import LoadingComponent from '../ReusableComponents/loading';

const apiClient = new MainApiClient();

const PAGE_COMPONENTS_REGISTRY ={
    TokenManagement: TokenManagement,
    UsageActivity: UsageActivity
}

export default function Main() {
    
        let { path } = useRouteMatch();
        const [isLoading, setIsLoading] = useState(true);
        const [tabsData, setTabsData] = useState();

        //useEffect hooks 2nd parameter: Empty array so that useEffect will be only called once.(for mounting only)
        useEffect(()=> {
            apiClient.fetchTabsData('/me').then(response => {
                setTabsData(response.data.modules)
                setIsLoading(false)
            }).catch(error => {
                Promise.reject(error)  
            })
        },[])
        
        return (
            isLoading ? <LoadingComponent styles={{marginTop:'15em'}}/>:
            <>
                <RenderHeaderTabs tabs={tabsData}/>
                <div className="container-fluid px-5 pt-3">
                    <Switch>
                        {
                            tabsData.map(value => <Route
                                key={value.title}
                                exact path={`${path}` + value.path}
                                component={PAGE_COMPONENTS_REGISTRY[value.component_name]}
                            />)
                        }
                        <Redirect exact from={`${path}`}  to={`${path}` + tabsData[0].path}/>
                    </Switch>
                </div>
            </>
        )
}