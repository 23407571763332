import React from "react";
import './UsageActivity.scss'
import LoadingComponent from '../../ReusableComponents/loading';
import MainApiClient from '../../ApiClient/apiClient';
import Chart from "react-apexcharts";
import DateRangePicker from "./DataRangePicker";
import {computeDate} from "./DateComputations"

const apiClient = new MainApiClient();

const PRE_DEFINED_OPTIONS = [
    { label: '1 Hour', value: '1|hour'},
    { label: '4 Hours', value: '4|hour'},
    { label: '12 Hours', value: '12|hour'},
    { label: '1 Day', value: '1|day'},
    { label: '3 Days', value: '3|day'},
    { label: '1 Week', value: '1|week'}
    // { label: '2 Weeks', value: computeDate('week', 2, new Date())}
] 

export default class UsageActivity extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            isLoading : true,
            isErrorOnFetch : false,
            data: undefined,
            dateRange: [new Date(computeDate('hour', 1, new Date())), new Date() ],
            selectedPreDefineDate: '1|hour'
            
        }
    }

    componentDidMount() {
        this.fetchUsageActivityApi(false)
    }
    
    fetchUsageActivityApi(isFromDateSelection) {
        const preDefineValue = this.manipulateSelectedPreDefineValue(this.state.selectedPreDefineDate)
        const dateParams = isFromDateSelection ? this.state.dateRange : [new Date(preDefineValue), new Date()]

        this.setState({
            isLoading: true, 
            isErrorOnFetch: false
        },()=> {
            apiClient.fetchUsageActiviy('servicetoken-stats/',dateParams).then(response => {
                this.setState({
                    isLoading : false,
                    isErrorOnFetch : false,
                    data: response.data
                })
            }).catch(error => {
                console.error(error)
                this.setState({
                    isLoading : false,
                    isErrorOnFetch : true
                })
            })
        })
    }

    handleDateChange(dates) {
        const [start, end] = dates;
        
        this.setState({
            dateRange: [start,end]
        },()=>{
            console.info(this.state.dateRange)
            if(end) {                
                this.fetchUsageActivityApi(true)
            }
        })
    }
    
    handlePreDefineDropdownChange(e) {
        this.setState({
            selectedPreDefineDate : e.target.value,
            dateRange: [ new Date(this.manipulateSelectedPreDefineValue(e.target.value)), new Date()],            
        },()=>{
            this.fetchUsageActivityApi(false)
        })
    }

    manipulateSelectedPreDefineValue(selectedValue) {
        const stringArr = selectedValue.split('|')        
        const selectedDate = computeDate(stringArr[1], stringArr[0], new Date())

        return selectedDate
    }

    renderGraph() {
        if(this.state.isLoading) {
            return <LoadingComponent  styles={{marginTop:'5em'}}/>
        }

        if(this.state.isErrorOnFetch) {
            return <div className="text-center">
                <h5>Encountered an error while loading the graph. Kindly refresh the page</h5>
                <p><small>If still encountering the error after refreshing, please contact the administrator. Thank you</small></p>
            </div>
        }
        
        const seriesKeys  = Object.keys(Object.values(this.state.data)[0])
        

        const seriesValue = seriesKeys.map(key => {
            return {
                name: key,
                data : Object.keys(this.state.data).map(dateVal => {
                    return {
                        x : dateVal,
                        y : this.state.data[dateVal][key]
                    }
                })
            }
        })

    
        return  <Chart
            options={{
                xaxis : {
                    type: "datetime",
                    labels : {
                        datetimeUTC: false
                    }
                }
            }}
            series={seriesValue}
            type="line"
            height="475"

        />
        
    }

    render() {
        return (
           <>
                <DateRangePicker
                    onDateChange={this.handleDateChange.bind(this)}
                    startDate={this.state.dateRange && this.state.dateRange.length > 0 && this.state.dateRange[0]}
                    endDate={this.state.dateRange && this.state.dateRange.length > 0 && this.state.dateRange[1]}
                    predefinedOptions={PRE_DEFINED_OPTIONS}
                    onDropdownChange={this.handlePreDefineDropdownChange.bind(this)}
                    selectedPreDefineDate={this.state.selectedPreDefineDate}
                />
                {this.renderGraph()}
           </>
        )
    }
}