import { useEffect,useState } from "react"
import LoadingComponent from "../ReusableComponents/loading";
export default function GenerateTokenModal(props) {

    const [showTokenGenerationContent, setShowTokenGenerationContent]= useState(true);
    const [description, setDescription]= useState('');

    // useEffect(()=> {
    //     if(props.showGenerateTokenModal) {
    //         setDescription('')
    //         console.info("MODAL RENDERED")
    //     }
    // },[props.showGenerateTokenModal])
    function handleSaveClick(){
        props.generateTokenList(description)
        setShowTokenGenerationContent(false)
        setDescription('')
    }
    function handleCloseClick() {
        props.setShowGenerateTokenModal(false)
        setShowTokenGenerationContent(true)
        setDescription('')
    }
    const tokenGenerationContent = <>
        <div className="modal-body">
            <p className="text-center">New Token will be generated after saving.</p>
            <div className="mb-3">
                <label hmtlfor="descriptionTextArea" className="form-label">Description:</label>
                <textarea className="form-control" id="descriptionTextArea" rows="3" value={description} onChange={(e)=>setDescription(e.target.value)}></textarea>
            </div>                
        </div>
        
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=> handleCloseClick()}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={()=> handleSaveClick()} disabled={description === '' || description === undefined}>Save</button>
        </div>
    </>

    const generatedTokenContent = props.isTokenGenerationLoading ? <LoadingComponent styles={{paddingTop:'1.5rem',paddingBottom:'1.5rem'}}/> : 
    <>
        <div className="modal-body text-center">
            <p>Token Successfully Saved!</p>
            <p>Your new token is <strong>{props.newTokenKey}</strong></p>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=> handleCloseClick()}>Close</button>
        </div>
    </>
    
    return (
        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="generateTokenModal" tabIndex="-1" aria-labelledby="generateTokenModalLabel" aria-hidden="true">
            {
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="generateTokenModalLabel">Token Generation</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=> handleCloseClick()} hidden={props.isTokenGenerationLoading}></button>
                    </div>
                    
                        {
                            showTokenGenerationContent ? tokenGenerationContent : generatedTokenContent
                        }
                    </div>
                </div> 
            }
        </div>
    )
}