import React, { useEffect,useState } from 'react';
import MainApiClient from '../../ApiClient/apiClient'
import LoadingComponent from '../../ReusableComponents/loading';
import RenderTokenRows from './RenderTokenRows'
import GenerateTokenModal from '../../Modals/generateTokenModal';

const apiClient = new MainApiClient();


export default function TokenManagement() {
    const [isLoading, setIsLoading] = useState(true);
    const [tokenList, setTokenList] = useState([]);
    const [showGenerateTokenModal, setShowGenerateTokenModal] = useState(false);
    const [isTokenGenerationLoading, setIsTokenGenerationLoading]= useState(false);
    const [newTokenKey, setNewTokenKey] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const DATA_LIMIT = 10;    
    const PAGE_LIMIT = 5;
    const PAGES_AVAILABLE = tokenList ? Math.ceil(tokenList.length/DATA_LIMIT) : 0;
    const showPagination = tokenList && tokenList.length > DATA_LIMIT ? true : false;  
    const [pages,setPages] = useState(tokenList.length / DATA_LIMIT)  
    
    useEffect(()=> {
        apiClient.fetchTokenList('/servicetoken/').then(response => {
            setIsLoading(false)
            setTokenList(response.data)
        }).catch(error => {
            Promise.reject(error)  
        })
    },[])

    function generateTokenList(desc) {
        setIsLoading(true)
        setIsTokenGenerationLoading(true)
        apiClient.generateTokenList('/servicetoken/',desc).then(response => {
            setIsLoading(false)
            setIsTokenGenerationLoading(false)
            setNewTokenKey(response.data && response.data.key)
            setTokenList([...tokenList, response.data])
        }).catch(error => {
            setIsLoading(false)
            setIsTokenGenerationLoading(false)
            Promise.reject(error)  
        })
    }

    // Returns the data to be shown in the current page
    function getPaginatedData() {
        const startIndex = currentPage * DATA_LIMIT - DATA_LIMIT;
        const endIndex = startIndex + DATA_LIMIT;

        return tokenList && tokenList.slice(startIndex, endIndex);
    }

    
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }


    // Return the pagination to be shown 
    function getPaginationGroup() {
        let start = Math.floor((currentPage - 1) / PAGE_LIMIT) * PAGE_LIMIT;
        
        // based from the pageLimit and the current page.
        // for our pageLimit 5, returns [1,2,3,4,5]
        // when start is 5, return [6,7,8,9,10] for the pagination to be continous
        return new Array(PAGE_LIMIT).fill().map((_, index) => start + index + 1);
    }

    const tableHeaders = tokenList && tokenList.length > 0 ? Object.keys(tokenList[0]) : []

    const tableList = <div className="table-responsive">
        <table className="table table-striped table-hover table-sm " id="tokenList_tbl" >
            <thead>
                <tr  style={{backgroundColor: '#f1f1f1'}}>
                    {   
                        tableHeaders.map(val => {
                            return <th className={`${val === 'id' ? null : 'text-capitalize'} bg-primary bg-gradient text-white`} key={val}>{val} </th>
                        }) 
                    }
                </tr>
            </thead>
            <tbody>
                {
                    getPaginatedData().map((data, index) => {
                        return <tr key={index}>
                            <RenderTokenRows rowData={data}/>
                        </tr>
                    })
                }
            </tbody>
        </table>
        {
            showPagination && <nav aria-label="Page navigation">
                <ul className="pagination pagination-sm justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} >
                        <button className="page-link" onClick={()=> setCurrentPage(currentPage - 1)}><i className="bi bi-caret-left-fill"/></button>
                    </li>
                    {
                        getPaginationGroup().map((value,index)=>{
                            if(PAGES_AVAILABLE >= index+1) {
                                return <li className={`page-item ${currentPage === value ? 'active' : 'null'}`}  key={index} onClick={changePage}><button className="page-link">{value}</button></li>
                            } else {
                                return <li className="page-item disabled"  key={index}><button className="page-link">{value}</button></li>
                            }
                        })
                    }
                    <li className={`page-item ${currentPage === pages || tokenList.length <= DATA_LIMIT? 'disabled' : ''}`} >
                        <button className="page-link" onClick={()=> { setCurrentPage(currentPage + 1); setPages(Math.ceil(tokenList.length / DATA_LIMIT))} }><i className="bi bi-caret-right-fill"/></button>
                    </li>
                </ul>
            </nav>
        }
    </div>

    const emptyResult = <div className="row g-0 fs-4 p-5 border">
        <p className="text-center">No Token Available</p>
    </div>

    const content = tokenList && tokenList.length > 0 ? tableList : emptyResult
    
    return(
        <>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#generateTokenModal" onClick={()=>setShowGenerateTokenModal(true)} disabled={isLoading}>Generate Token</button>
            </div>
            { isLoading ? <LoadingComponent  styles={{marginTop:'5em'}}/> : content }
            <GenerateTokenModal generateTokenList={generateTokenList} 
                setShowGenerateTokenModal={setShowGenerateTokenModal} 
                showGenerateTokenModal={showGenerateTokenModal} 
                isTokenGenerationLoading={isTokenGenerationLoading}
                newTokenKey={newTokenKey}
            /> 
        </>
    )
}