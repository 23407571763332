import { applyAuthTokenInterceptor } from 'axios-jwt'
import axios from 'axios';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_DOMAIN })

const requestRefresh = (refresh) => {
    return new Promise((resolve, reject) => {
        axios.post('/token/refresh/', {
            refresh
        })
            .then(response => {
                resolve(response.data.access);
            }, reject);
    });
};

const checkUrl = (urlString) => {
    const path = urlString && urlString.split('?')[0]
    if(path && path.endsWith('/')) {
        return urlString
    } else {
        return urlString + '/'
    }
}

export const APIClient =() => {
    axiosInstance.interceptors.request.use((config) => {
        localStorage.setItem('LAST_API_CALL', (new Date()).getTime())
        return config;
    })

    applyAuthTokenInterceptor(axiosInstance,{ requestRefresh })
    axiosRetry(axiosInstance, { retries: 3 });
    return axiosInstance
}

class MainApiClient {
    async fetchTabsData(url) {
        try {
            const response = await APIClient().get(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async fetchTokenList(url) {
        try {
            const response = await APIClient().get(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async updateTokenList(url) {
        try {
            const response = await APIClient().post(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async deactivateToken(url) {
        try {
            const response = await APIClient().delete(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async activateToken(url) {
        try {
            const response = await APIClient().post(checkUrl(url));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async generateTokenList(url,desc) {
        try {
            const response = await APIClient().post(checkUrl(url),{
                'description' : desc
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async updateDescription(url,desc,key) {
        try {
            const response = await APIClient().put(checkUrl(url+key), {
                'description': desc
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async fetchUsageActiviy(url,dateTime) {
        try {
            const response = await APIClient().get(checkUrl(url,dateTime),{
                params : {
                    date_from : dateTime[0].toISOString(),
                    date_to: dateTime[1].toISOString()
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }




}


export default MainApiClient;