import React from 'react';
import MainApiClient from '../../ApiClient/apiClient'
import LoadingComponent from '../../ReusableComponents/loading';

const apiClient = new MainApiClient();
const HEADER_KEYS = {
    DESCRIPTION : 'description',
    KEY: 'key'
}


export default class RenderTokenRows extends React.PureComponent {
    state = {
        isRowLoading: false,
        isEdited: false,
        rowData: this.props.rowData
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(this.props.rowData) !== JSON.stringify(prevProps.rowData)) {
            this.setState({
                rowData: this.props.rowData
            })
        }
    }

    editDescClick() {
        this.setState(prevState=> {
            return {
                isEdited: !prevState.isEdited
            }
        })
    }

    saveDescClick() {
        this.setState(prevState=> {
            return {
                isRowLoading: true,
                isEdited: !prevState.isEdited
            }
        },()=>{
            apiClient.updateDescription('/servicetoken/',this.state.rowData[HEADER_KEYS.DESCRIPTION],this.state.rowData[HEADER_KEYS.KEY]).then(response => {
                this.setState({
                    isRowLoading: false,  
                    rowData : response.data
                })   
            }).catch(error => {
                Promise.reject(error)  
            })
        })
        
    }

    setDescription(val) {
        this.setState(prevState =>{ 
            return {
                rowData : {
                    ...prevState.rowData,                    
                [HEADER_KEYS.DESCRIPTION]:val
                }
            }
        })
    }

    cancelDescClick() {
        this.setState(prevState=> {
            return {
                isEdited: !prevState.isEdited, 
                rowData : {
                    ...prevState.rowData,                    
                    [HEADER_KEYS.DESCRIPTION]: this.props.rowData[HEADER_KEYS.DESCRIPTION]
                }     
            }
        })
    }

    fetchActionApi(url,method) {
        switch(method.toLowerCase()) {
            case 'post':
                apiClient.activateToken(url).then(response => {
                    this.setState({
                        isRowLoading: false,  
                        rowData : response.data
                    })   
                }).catch(error => {
                    Promise.reject(error)  
                })
            break;
            case 'delete':
                apiClient.deactivateToken(url).then(response => { 
                    this.setState({
                        isRowLoading: false,  
                        rowData : response.data
                    })     
                }).catch(error => {
                    Promise.reject(error)  
                })
            break;

            default: return null
        }
    }

    handleActionsClick(actioName,url,method) {
        this.setState({
            isRowLoading : true
        },()=>{
            switch(actioName.toLowerCase()) {
                case 'activate':
                    this.fetchActionApi(url,method)
                break;
                case 'deactivate':
                    this.fetchActionApi(url,method)
                break;

                default:
                    this.fetchActionApi(url)

            }
        })
    }

    renderDisplayValue(headerKey) { 
        let displayValue;
        switch(headerKey) {
            case 'description':
                const displayView = <div>{this.state.rowData[headerKey]} 
                        <button type="button" className="btn btn-sm"  onClick={()=>this.editDescClick()}><i className="bi bi-pencil-square"/></button>
                    </div>
    
                const editView = <div className="col d-flex">
                        <input type="text" id="description" className="form-control form-control-sm" name="description" value={this.state.rowData[headerKey]}  onChange={(e)=> this.setDescription(e.target.value)} />
                        <button type="button" className="btn btn-sm"  onClick={()=>this.saveDescClick()}><i className="bi bi-check2-square"/></button>
                        <button type="button" className="btn btn-sm"  onClick={()=>this.cancelDescClick()}><i className="bi bi-x-square"/></button>
                </div>
                displayValue = this.state.isEdited ? editView : displayView
            break;
    
            case 'actions' :
                const actionButtons = this.state.rowData[headerKey].map(val =>{
                    return <button type="button" className={`btn btn-sm ${val.name.toLowerCase() === 'activate' ? 'btn-success' : 'btn-danger'} text-capitalize`} key={val.name}
                            onClick={()=>this.handleActionsClick(val.name,val.url,val.method)}>{val.name}</button>
                })
    
                displayValue = <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    { actionButtons }
                </div>
            break
    
            default:
                displayValue = typeof this.state.rowData[headerKey] === "boolean" ? this.state.rowData[headerKey].toString() : this.state.rowData[headerKey]
        }
        return displayValue
    }

    render() {
        return (
            Object.keys(this.state.rowData).map((key, index) => {
                return <td key={key+'_'+index}> { this.state.isRowLoading ? <LoadingComponent isForRowLoading={true} /> : this.renderDisplayValue(key) } </td>
            })
        )
    }
}